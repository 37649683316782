const pageHome = document.querySelector(".body_home");
const pageBlog = document.querySelector(".body_blog");
const pageSingle = document.querySelector(".body_single_blog");
const pageOevento = document.querySelector(".body-o-evento");
const pageQuemSomos = document.querySelector(".body_quemsomos");
const pageProgramacao = document.querySelector(".body_programacao");
const pageAtracoes = document.querySelector(".body_eventos ");
const pageComingSoon = document.querySelector(".body-coming-soon");
const pageContato = document.querySelector(".body_contato");
const pageVisitantes = document.querySelector(".body_infos");
const pageCredenciamento = document.querySelector(".body_credenciamento");
const pageEspacos = document.querySelector(".body_espacos");
const pageServicos = document.querySelector(".body_servicos");
const pageExpositores = document.querySelector(".body_expositores");

// SCRIPT SENHA LOGIN
import mostrarSenha from "./mostrarSenha.js"
import showFormErrors from "./showFormErrors.js";

mostrarSenha()
showFormErrors()

// SECTIONS
import salebrandPortfolio from "./salebrand/salebrandPortfolio.js";
import salebrandSobre from "./salebrand/salebrandSobre.js";
import salebrandClientes from "./salebrand/salebrandClientes.js";
import salebrandContato from "./salebrand/salebrandContato.js";
import homejs from './scriptsPaginas/home.js'
import menu from "./menu.js";
import singleBlog from "./scriptsPaginas/single-blog.js";
import oEvento from "./scriptsPaginas/oEvento.js";
import modalAtracoes from "./modal-atracoes.js";
import baseComingSoon from "./coming_soon_form.js";
import contatoForm from "./contato-form.js";
import { animaAoScroll } from "./animations/global-animation.js";
import animationHome from "./animations/animationHome.js";
import initCounterUp from "./counterUp.js";
import animationOevento from "./animations/animationOevento.js";
import { share } from "./share-icons.js";
import modalApi from "./api/fetchModalAtracao.js";
import richTextParse from "./richTextScript.js";
import animationQuemSomos from "./animations/animationQuemSomos.js";
import animationCredenciamento from "./animations/animationCredenciamento.js";
import programacaoApi from "./api/fetchProgramacao.js";
import modalExpositoresAPI from "./api/fetchModalExpositores.js";
import galeriaHome from "./scriptsPaginas/galeriaHome.js";
import galeriaAPI from "./api/fetchGaleria.js";

galeriaAPI()

galeriaHome()

gsap.registerPlugin(ScrollTrigger)

salebrandPortfolio();
salebrandSobre();
salebrandClientes();
salebrandContato();
menu();
// ★ Pages Scopo
modalAtracoes();
animaAoScroll();
initCounterUp();

const swiperPatrocinadores = new Swiper('.swiper-banner-patrocinadores',{
  spaceBetween: 16,
    pagination:{
        el: '.swiper-banner-patrocinadores .swiper-pagination',
        clickable: true,
        type: 'bullets',
    },
    autoplay: {
        delay: 3000
    }
});

const swiperPatrocinadoresServicos = new Swiper('.swiper-banner-patrocinadores-servicos',{
  spaceBetween: 16,
    pagination:{
        el: '.swiper-banner-patrocinadores-servicos .swiper-pagination',
        clickable: true,
        type: 'bullets',
    },
    autoplay: {
        delay: 3000
    }
});

const swiperHome = new Swiper('.swiper-banner-home',{
  spaceBetween: 16,
    pagination:{
        el: '.swiper-banner-home .swiper-pagination',
        clickable: true,
        type: 'bullets',
    },
    autoplay: {
        delay: 2000
    }
});

if (pageHome) {
 
  homejs();

  contatoForm();

  animationHome();


  // ( in home parameter )
  programacaoApi(true);
  modalApi();


  const programacaoWrapper = document.querySelector('#programacao');


  programacaoWrapper && programacaoWrapper.addEventListener('click', (e) => {
  
    const target =  e.target.closest('.continuar-lendo')
   
    if(!target) return 
    console.log(target, 'target')
      const parentTarget = target.closest('.content-for-p')
      parentTarget.classList.toggle('open')

  })


  gsap.to('.body_home .ingresos-fixed',{
      scrollTrigger:{
        trigger: '#banner-home',
        start: 'top+=50px'
      },
    y:0
  })


}else if(pageBlog){ 


  const  swiperDestaque = new Swiper('.swiper-destaque', {
    slidesPerView: 1.2,
    spaceBetween: 16,
    
    breakpoints:{
      600:{
        slidesPerView: 2,
        spaceBetween: 16, 
      },
      1024:{
        slidesPerView: 2,
        spaceBetween: 32, 

      }
    }
  })
}else if(pageSingle){

  // swiper-midia

  share();
  singleBlog();
  richTextParse();
}else if(pageOevento){
  oEvento();

  animationOevento();
}else if(pageQuemSomos){

  const swiperFotos = new Swiper('.swiper-fotos', {
    slidesPerView: 1.2,
    spaceBetween: 16,
    centeredSlides: true,
    centeredSlidesBounds: true,
    // loop: true,
    autoplay:{
      delay: 4000,
      disableOnInteraction: false
    },
    breakpoints:{
      1024:{
        slidesPerView: 'auto',
        spaceBetween: 24,
      }
    }
  })
   const  swiperNossaHistoria = new Swiper('.swiper-nossa-historia', {
    slidesPerView: 1.2,
    spaceBetween: 16,
    pagination: {
      el: '.swiper-nossa-historia .pagination',
      type: 'fraction',
      renderFraction: function (currentClass, totalClass) {
        console.log(currentClass);
        return '<span class="' + currentClass + '"></span>' +
                ' DE ' +
                '<span class="' + totalClass + '"></span>';
    },
    },
    navigation: {
      nextEl: '.swiper-nossa-historia .button-right',
      prevEl: '.swiper-nossa-historia .button-left',
    },

    breakpoints:{
      768:{
        slidesPerView: 2,
        spaceBetween: 24,
      },

      1024:{
        slidesPerView: 3,
        spaceBetween: 32,
      }
    }
   })



   animationQuemSomos()

  Fancybox.bind('[data-fancybox="quem-somos-galeria"]', {
    // Your custom options
  });

}
else if (pageComingSoon) {
  baseComingSoon();
}else if(pageProgramacao){
  programacaoApi();
  modalApi();
  
  
  const swiperInfiniteProgramacao = new Swiper('.swiper-infinite-programacao',{
    slidesPerView: 'auto',
    allowTouchMove: false,
    spaceBetween: 40,
    speed: 2000,
    loop: true,
    autoplay:{
      delay: 0
    },
    breakpoints:{
      1024:{
        spaceBetween: 64,
      }
    }

  })

  const toggleFilter = document.querySelector('.filtrar-por-data');

    toggleFilter && toggleFilter.addEventListener('click', () =>{
      const sidebar = document.querySelector('.sidebar')
      sidebar.classList.toggle('open')
    })

 
  const programacaoWrapper = document.querySelector('.programacoes');


  programacaoWrapper && programacaoWrapper.addEventListener('click', (e) => {

    const target =  e.target.closest('.continuar-lendo')
    
    if(!target) return

      const parentTarget = target.closest('.content-for-p')
      parentTarget.classList.toggle('open')

  })


 
}else if(pageAtracoes){
  modalApi();



  const urlParams = new URLSearchParams(window.location.search);
  const nomeValue = urlParams.get('nome');

  // Display the value in an HTML element
  const outputElement = document.querySelector('.busca input[name=nome]');
  if (nomeValue) {
      outputElement.value =  nomeValue;
  } else {
      outputElement.value = "";
  }


}else if(pageContato){

  const swiperInfiniteProgramacao = new Swiper('.swiper-infinite-programacao',{
    slidesPerView: 'auto',
    allowTouchMove: false,
    spaceBetween: 20,
    speed: 4000,
    loop: true,
    autoplay:{
      delay: 0
    },
    breakpoints:{
      1024:{
        spaceBetween: 64,
      }
    }

  })

  contatoForm();
  
}else if(pageCredenciamento){
  animationCredenciamento();
}else if(pageVisitantes){
  const sideTitles = document.querySelectorAll('.side-bar h2');
  const blockItems = document.querySelectorAll('.col-informacoes  .block-item');
  sideTitles.forEach((item,index) => {
    item.addEventListener('click', (e)=>{

      sideTitles.forEach((i) => i.classList.remove('active'))
      item.classList.add('active')

      const gotoblock = blockItems[index];
      if(gotoblock){
        gotoblock.scrollIntoView();
      }
      // console.log(blockItems[index])
    })
  })
}
else if(pageEspacos){

    const swiperCategoriaEspaco = new Swiper('.swiper-categoria-espaco', {
      slidesPerView: 'auto',
      // centeredSlides: true,
    // centeredSlidesBounds: true,
    });

    const swiperCuradores = new Swiper('.swiper-curadores', {
      slidesPerView: 2.2,
      spaceBetween: 16,

      breakpoints:{
        1024:{
          slidesPerView: 5,
        }
      },
      
    })

    const swiperEspaco = new Swiper('.swiper-espaco', {
      slidesPerView: 1,
      spaceBetween: 16,

      pagination: {
        el: '.swiper-espaco .pagination',
        type: 'fraction',
        renderFraction: function (currentClass, totalClass) {
          console.log(currentClass);
          return '<span class="' + currentClass + '"></span>' +
                  ' DE ' +
                  '<span class="' + totalClass + '"></span>';
        },
      },
      navigation: {
        nextEl: '.swiper-espaco .button-right',
        prevEl: '.swiper-espaco .button-left',
      },
    })

    const todosEspacos = document.querySelectorAll('.espaco')
    const allTabs = document.querySelectorAll('#categorias .tab')

    allTabs && allTabs.forEach(tab => {
      tab.addEventListener('click', (e) => {
        const target = e.target.closest('.tab')
        if(!target) return

        allTabs.forEach(es => es.classList.remove('active'))

        target.classList.add('active')
        
        const id = target.dataset.id
        
       const espaco =  document.querySelector(`#${id}`)
        

       if(espaco){
        todosEspacos.forEach(es => es.classList.remove('show'))
        console.log(espaco)
        espaco.classList.add('show')
       }
      })
    })
   


}else if(pageServicos){
    const swiperCategoriaEspaco = new Swiper('.swiper-categoria-espaco', {
      slidesPerView: 'auto',
      // centeredSlides: true,
    // centeredSlidesBounds: true,
    });

    const swiperEspaco = new Swiper('.swiper-espaco', {
      slidesPerView: 1,
      spaceBetween: 16,
      

      pagination: {
        el: '.swiper-espaco .pagination',
        type: 'fraction',
        renderFraction: function (currentClass, totalClass) {
          console.log(currentClass);
          return '<span class="' + currentClass + '"></span>' +
                  ' DE ' +
                  '<span class="' + totalClass + '"></span>';
        },
      },
      navigation: {
        nextEl: '.swiper-espaco .button-right',
        prevEl: '.swiper-espaco .button-left',
      },
    })

    document.addEventListener("DOMContentLoaded", function() {
      var tabs = document.querySelectorAll('.tab');
  
      tabs.forEach(tab => {
          tab.addEventListener('click', function() {
              tabs.forEach(item => item.classList.remove('active'));
              tab.classList.add('active');
  
              var tabNumber = tab.querySelector('p').classList[0].split('-')[1];
              var targetArticle = document.getElementById('servico-' + tabNumber);
  
              var articles = document.querySelectorAll('.espaco');
              articles.forEach(article => article.classList.remove('active'));
              targetArticle.classList.add('active');
          });
      });
  });
}else if(pageExpositores){
    const lettersDropDown = document.querySelector('.floating-letters')
    const selectToClick = document.querySelector('.filtro-to-click')

    selectToClick && selectToClick.addEventListener('click', (e) => {
      const target = e.target.closest('.filtro-to-click')
        if(!target) return
        target.classList.toggle('show')
    

    })


    modalExpositoresAPI();
}





// INIT YOUTUBE 
if(window.Fancybox){
  Fancybox.bind('[data-fancybox="youtube"]', {
    // Your custom options
  });


}

document.addEventListener("DOMContentLoaded", () =>{
    document.body.classList.add("dcl");



    // Get the element with the class "ingresos-fixed"
const ingresosFixedElement = document.querySelector('.ingresos-fixed');
const footerElement = document.getElementById('footer-bienal');

// Get the height of the "ingresos-fixed" element
if(ingresosFixedElement && footerElement){
  console.log('runned ?')
  const ingresosFixedHeight = ingresosFixedElement.clientHeight;
  footerElement.style.paddingBottom = `${ingresosFixedHeight}px`;


}

// Add padding to the element with the ID "footer"


 
    jQuery.fn.FCInstagram.accessData = {
        accessToken: "IGAASPEiRjZBYFBZAE5DTXZAtRlo4cWVVSGFMSTNZASGlTRUhueW5rUm1jMVVaSjY0cDI5aXp6XzVKdkk5cFU1NEdxVHc3aENKTF9FT0RubTFMOGg4ZA2c5WXNfcmdnRmYwTW92X0traGdFRGhmZADJfazFGSllOVHpqZA3hxSXdUdXlBRQZDZD"// Token
       // Token
    };
    $('#instafeed').FCInstagram({
        max: 4, // A number between 1 and 25 of photos to show. Default: 9
        autoplay: true, // Set autoplay video: true/false. Default: false
        complete: function () { // A callback function to execute after the display of the photos.
            console.log('completed');
            const instaItems = document.querySelectorAll('#instafeed a')

            instaItems.forEach(item => {
              const htmlHover =  `<div class="content-hover">
                <svg>
                  <use xlink:href="#icon_instagram"></use>
                </svg>
                <p class="see-insta">Ver no instagram</p>
                <svg class="click-icon"> 
                  <use xlink:href="#icon_click"></use>
                </svg>
              </div>`
              item.insertAdjacentHTML('beforeend',htmlHover)
            })
            console.log(instaItems)
        }
    });     
  }
);
