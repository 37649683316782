export default function modalExpositoresAPI(){
    const modalInner = document.querySelector('.modal-guts')
    const modalWrapper = document.querySelector('.modal-atracoes')
    const closeModal = document.querySelector('#close-button')
    // const data = [{
    //     nome: 'localizacao',
    //     Localizacao: 'teste'
    //     }
    // ]


    const expositores = document.querySelectorAll('.expositor-item');

    function rendermodalContentOne(data){
        console.log(data)
       
        
        const { info, programacao} = data;

        console.log(info)
        console.log(programacao)


        function compararEventos(a, b) {
            const dataHoraA = new Date(`${a.data}T${a.horario}`);
            const dataHoraB = new Date(`${b.data}T${b.horario}`);
            return dataHoraA - dataHoraB;
        }
        const programacaoOrdenada = programacao?.sort(compararEventos);

            console.log('ordenada', programacaoOrdenada);

       
        const htmlStepOne =  ` 
        <div class="step-1-item">
      
        <div class="header-expositor">
        <div class="header-1-wrapper ">
            <div class="nome-wrapper">
                <p class="info-label" >Nome</p>
                <p class="info-nome">${info?.nome}</p>
            </div>  
            <div class="button-wrapper go-to-2">
                <button class="button">Ver programação</button>
            </div>  
        </div>
        <div class="header-2-wrapper ">
            
                <div class="nome-wrapper">
                    <p class="info-label" >localização</p>
                    <p class="info-nome">${ info?.local}</p>
                </div>
                <div class="nome-wrapper">
                    <p class="info-label" >pavilhão</p>
                    <p class="info-nome">${info?.pavilhao}</p>
                </div>
            
            
        </div>         
    </div>
    <div class="button-wrapper only-mobile-btn go-to-2">
        <button class="button">Ver programação</button>
    </div> 

    <div class="informacoes-wrapper">
        <div class="col-1 selos">
            <div class="section-title">
                <div class="badge-wrapper bg-azul" >
                <p class="badge ">selos</p>
                </div>
            </div>
            <ul>
            ${info?.selos?.map( selo => {
                return `<li>${selo}</li>`
            }).join('')}
               
            </ul>
        </div>
        <div class="col-2 livros">
            <div class="section-title">
                <div class="badge-wrapper bg-azul" >
                <p class="badge ">livros mais procurados</p>
                
                </div>
            </div>
            <ul class="livros-wrapper">
                ${info?.livros?.map( livro => {
                    return ` <li>
                            <div class="img-wrapper">
                                <img src="${livro?.imagem}" alt="">
                            </div>
                            <p class="nome-livro">${livro?.nome}</p>
                        </li>`
                }).join('')}            
            </ul>
        </div>
      </div> 
    </div> 
    

    <div class="step-2-item">
        <div class="header-expositor step-2">
            <button class="flex items-center voltar-btn go-to-1"> 
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.16602 10.0002H15.8327" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.33268 5.8335L4.16602 10.0002" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.33268 14.1667L4.16602 10" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span>VOLTAR</span>
            </button>

            <p class="info-nome">programação</p>
            <p class="confirmada-title">Atração confirmada para o evento</p>
        </div>

        <div class="lista-programacao">
            <ul>

            ${programacaoOrdenada.length > 0 ?
                programacaoOrdenada?.map(item => {


                const dataCriada = new Date(item.data);
                const dataFormatada = dataCriada.toLocaleDateString('pt-BR', {
                  timeZone: 'UTC',
                });
                console.log(dataFormatada);

                return `   <li>
                        <div class="section-title">
                            <div class="badge-wrapper bg-azul" >
                                <p class="badge ">${dataFormatada} • ${item?.horario}</p>
                            </div>
                            <div class="informacoes">
                                <div class="group-1">
                                    <div class="group">
                                        <p class="label">Espaço</p>
                                        <p class="info-nome">${item?.espaco}</p>
                                    </div>
                                    <div class="group">
                                        <p class="label">sessão</p>
                                        <p class="info-nome">${item?.tipo_sessao ? item.tipo_sessao : '-'}</p> 
                                    </div>
                                    <div class="group">
                                        <p class="label">autor/participante</p>
                                        <p class="info-nome">${item?.participante}</p>
                                    </div>
                                </div>
                                
                                <div class="group">
                                    <p class="label">Descrição</p>
                                    <p class="info-nome">${item?.descricao}</p>
                                </div>
                                
                            </div>
                        </div>
                    </li>`
            }).join('') : ''}

            ${programacaoOrdenada.length == 0 ? `<p> Em breve!</p>` : ''}  
            </ul>
        </div>
    </div>
    `
    modalInner.innerHTML = htmlStepOne
    }


    function getExpositor(id){
      
        //  alert('ID: '+ id )

         const url = `/home/api/expositor/${id}`
         axios.post(url)
             .then((response) => {
                console.log(response);
              
                           
             
                 if(response.data){
                    rendermodalContentOne(response.data);               
                 }
             })
             .catch((response) => {
                console.log('erro ?')
                 console.log(response)               
             });
    
    }



    expositores.forEach(item => {
        item.addEventListener('click', (e) => {
            const target = e.target.closest('.expositor-item')
            if(!target) return
            const id = target.dataset.expositorId
          
            // expositores.target
            
            getExpositor(id)
        })
    })


    if(closeModal){
        closeModal.addEventListener('click', () =>{
            modalWrapper.classList.add('active-step-1')
            modalWrapper.classList.remove('active-step-2')
        })
    }
  
    

    modalInner.addEventListener('click', (e) => {
        const target =  e.target
        const target1 = target.closest('.go-to-1')
        const target2 = target.closest('.go-to-2')
  
    //  console.log(target)
     console.log(target1)
     console.log(target2)
        // console.log(target1)
        // console.log(target2)
        // if(target1 || !!target2) return
        if(target1){
            modalWrapper.classList.add('active-step-1')
            modalWrapper.classList.remove('active-step-2')
        }else if(target2){
            modalWrapper.classList.remove('active-step-1')
            modalWrapper.classList.add('active-step-2')
        }else{
            return
        }
    
    })

 
    
    
}

